console.log('in main');

import Vue from 'vue';
import './sass/style.scss';

import './env';
import './components';

import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { createRouter } from './router';
import { createStore } from './store';
import { sync } from 'vuex-router-sync';
import './plugins/composition-api';
// import './plugins/gtag';
import './plugins/handle-error';
import './plugins/vue-hammer';
import './plugins/vue-popover';
// import './pwa/registerServiceWorker';

import { createVuetify } from './plugins/vuetify';
import { nprogress } from './plugins/vue-nprogress';

import App from './App.vue';
import { createAuth } from './plugins/auth';
import { usePageVisibility, setI18n, useI18n } from 'vue-composable';

import VueMeta from 'vue-meta';

import { Role } from './store/entities';
import { setLanguages } from './data/i18n/languages';
import { useState } from '@u3u/vue-hooks';
import { getCurrentLanguageForIntialLoad, raiseGtagEvent } from './utils/helpers';
import { getAllRoles, getLanguage } from './services/api';
import { GtagEventName, intializeVueGtag } from './plugins/gtag';
import { LS_DEFAULT_WEBSITE_KEY } from './store/modules/auth';
import vuuri from 'vuuri';
import { createSyncfusion } from './plugins/syncfusion';
Vue.component('vuuri', vuuri);

(async () => {
  createSyncfusion();
  Vue.use(VueMeta, {
    keyName: 'head',
    // optional pluginOptions
    //refreshOnceOnNavigation: true
  });

  const vuetify = createVuetify();
  const store = createStore();
  let router = null;

  try {
    const rolesAndLanguage = await Promise.all([
      getAllRoles(),
      getLanguage(getCurrentLanguageForIntialLoad(), localStorage.getItem(LS_DEFAULT_WEBSITE_KEY)),
    ]);
    if (rolesAndLanguage?.[0]?.data) Role.insertOrUpdate({ data: rolesAndLanguage?.[0].data });
    if (rolesAndLanguage?.[1]?.data) setLanguages(rolesAndLanguage?.[1]?.data);

    router = createRouter();

    createAuth({ store, router });

    sync(store, router);
    intializeVueGtag();
    raiseGtagEvent(GtagEventName.every_page_refresh);
  } catch (error) {
    console.log(error?.response?.data?.error);
  }

  const app = new Vue({
    vuetify,
    router,
    store,
    nprogress,
    render: (h) => h(App),
  });

  app.$mount('#app');
})();
